@import "@/styles/varibles.less";

.eighth-page-container {
  background-color: #fff;

  flex: 0 0 auto;

  padding: 3rem 0;

  .main-content {
    width: 100%;

    .main-title {
      text-align: center;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem; /* 120% */
    }

    .recommend-links {
      margin-bottom: 0 !important;
    }
  }
}

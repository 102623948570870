.fifth-page-container {
  background-color: #fff;
  padding: 0 0.75rem;
  padding-top: 0.75rem;

  .fifth-page-layout {
    width: 100%;

    .main-content {
      width: 100%;
      padding: 1.25rem;
      border-radius: 1.75rem 0rem 1.75rem 1.75rem;
      background: rgba(0, 240, 160, 1);

      .main-content-title {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2rem; /* 133.333% */
        margin-bottom: 1.5rem;
        width: 15rem;
        text-transform: uppercase;
      }

      .main-content-sub-container {
        width: 100%;
        align-items: flex-end;

        .chat-item {
          display: flex;
          padding: 16px;
          flex-direction: column;
          align-items: flex-end;
          border-radius: 16px 0px 16px 16px;
          background: #fff;

          .chat-item-header {
            align-items: center;
            width: 100%;

            .chat-item-header-content {
              .chat-username {
                color: var(--Black, #000);
                text-align: right;
                font-size: 14px;
                font-weight: 600;
                line-height: 18px; /* 128.571% */
                text-transform: capitalize;
              }

              .chat-job-title {
                color: var(--Black, #000);
                text-align: right;
                font-size: 11px;
                font-weight: 400;
                line-height: 12px; /* 109.091% */
              }

              .chat-avatar {
                border-radius: 50%;
                flex-shrink: 0;
              }
            }
          }

          .chat-item-paragraph {
            color: var(--Black, #000);
            font-size: 12px;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
            border-left: 2px solid var(--Black, #000);
            padding-inline-start: 16px;
          }
        }
      }
    }
  }
}

.landing-sub-page-layout {
  // max-width: 1600px;
  // min-width: 1200px;
  // width: calc(100% - 96px);
  // height: 800px;
  // position: relative;

  // display: flex;
  // align-items: center;
}

.default {
  transform: translate3d(0px, 50px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
    skew(0deg, 0deg);
  opacity: 0;
  transform-style: preserve-3d;
  transition: all 500ms linear 0s;

  &.started {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
      skew(0deg, 0deg);
    opacity: 1;
  }

  &.ended {
    transition: none;
    animation-play-state: paused;
  }
}

.first-page-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;

  background-image: url("/newimages/mobile/topbg_mobile.png?v1");
  background-position: center;
  background-size: cover;

  .landing-v3-content {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 6.12rem 0.75rem;
    margin-top: 3.75rem;

    .main-title {
      margin-block: 0.75rem 0.5rem;

      text-align: center;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2.5rem; /* 166.667% */

      .emphasis {
        font-size: 2.25rem;
        font-weight: 900;
        line-height: 2.5rem;
        text-transform: uppercase;
      }
    }

    .sub-title {
      text-align: center;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1rem; /* 133.333% */
    }

    .mobile-sign-up-button {
      border-radius: 1.75rem;
      background: var(--Green, #00f0a0);

      display: flex;
      // padding: 0.5rem 0rem;
      height: 2.5rem;
      justify-content: center;
      align-items: center;

      color: var(--Black, #000);
      text-align: center;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem; /* 150% */

      margin-top: 1.5rem;
    }
  }

  .react-player-wrapper {
    position: relative;
    margin: 0 auto;

    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);

    width: 22.7778rem;
    height: 12.8125rem;
    border-radius: 8px;

    .react-player-cover {
      width: 22.7778rem;
      height: 12.8125rem;

      border-radius: 8px;
      background-image: url("/newimages/landing/jobs_normal.jpg");
      background-position: center;
      background-size: cover;
    }

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
    iframe {
      border-radius: 8px;
    }
  }
}

@import "@/styles/varibles.less";

.seventh-page-container {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.75rem;
  background-color: #fff;

  .seventh-page-layout {
    width: 100%;
    .main-content {
      width: 100%;
      align-items: center;
      padding: 3rem 1.4375rem;

      border-radius: 0rem 1.75rem 1.75rem 1.75rem;
      background: linear-gradient(96deg, #c9f9ff 0%, #cdffed 100%);
      .main-content-title {
        width: 20rem;

        text-align: center;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.5rem; /* 120% */
      }
      .mobile-sign-up-button {
        border-radius: 1.75rem;
        background: #000;

        display: flex;
        // padding: 0.5rem 0rem;
        height: 2.5rem;
        justify-content: center;
        align-items: center;

        color: #fff;
        text-align: center;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.5rem; /* 150% */
        width: 12.5rem;
      }
    }
  }
}

@import "@/styles/varibles.less";

.landing-page-layout.landing-page-layout-overwrite {
  width: 100%;
  min-height: 100vh;
  overflow: auto;

  background: #fff;
  display: flex;
  flex-flow: column nowrap;
}

.bg-class {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.error-content-class {
  left: 320px;
  top: 200px;
  z-index: 999;
  position: fixed;
}

.icon-right-class {
  position: fixed;
  top: 144px;
  right: 0;
  z-index: 100;
}

.subtitle-class {
  color: var(--Black, #000);
  font-size: 23px;
  font-weight: 700;
  line-height: 32px;
}

.subtitle-desc-calss {
  color: var(--Black, #000);
  font-size: 23px;
  font-weight: 200;
  line-height: 32px;
}

.desc-content-class {
  margin-top: 24px;
}

.desc-class {
  color: var(--black-45, rgba(0, 0, 0, 0.45));
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.desc-email-class {
  color: var(--black-45, rgba(0, 0, 0, 0.45));
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
  margin-inline-start: 4px;

  &:hover {
    color: var(--black-3, #000);
  }
}
.icon-class {
  margin-top: 32px;
}

.recommend-links {
  justify-content: center;

  .recommend-links-item {
    padding: 8px 16px;
    border-radius: 20px;
    background: var(--Black, #000);
    margin-inline-end: 0;
    color: var(--White, #fff);

    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    border: 0;
  }

  &.mobile {
    margin: 1.5rem auto;
    display: flex !important;
    align-items: center;
    flex-direction: column;

    .recommend-links-item {
      display: block;
      padding: 8px 12px;
      max-width: 90vw;
      margin-inline-end: 0;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      font-size: 11px;
      line-height: 14px;
    }
  }
}

.crawler-link {
  display: none;
}

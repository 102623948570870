@import "@/styles/varibles.less";

.sixth-page-container {
  background-color: #fff;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  padding-top: 0.75rem;

  .sixth-page-layout {
    width: 100%;

    .main-content {
      width: 100%;
      height: fit-content;
      padding: 1.25rem;

      border-radius: 0rem 1.75rem 1.75rem 1.75rem;

      background: #f2f4f5;

      .main-content-title {
        width: 15rem;

        color: #000;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2rem; /* 133.333% */
        text-transform: uppercase;
      }
      .main-content-sub-container {
        width: 100%;
      }

      .main-content-collapse {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        background-color: transparent;

        a.link {
          text-decoration: underline;
          color: #000;
        }

        :global {
          .ant-collapse-item {
            padding: 1rem;
            border-radius: 1rem;
            border-bottom: 0;

            background-color: #fff;

            > .ant-collapse-header {
              padding: 0;
              display: flex;
              align-items: center;

              font-size: 0.8125rem;
              font-weight: 600;
              gap: 1.12rem;
              line-height: 1.125rem;

              h3 {
                font-size: 0.8125rem;
              }

              > .ant-collapse-header-text {
                display: block;
                word-wrap: break-word;
              }

              > .ant-collapse-expand-icon {
                width: 1.5rem;
                height: 1.5rem;
                padding-inline-start: 0 !important;
                border: 1.5px solid #000;
                border-radius: 12px;

                flex-shrink: 0;
                justify-content: center;
                align-items: center;
              }
            }

            > .ant-collapse-content {
              > .ant-collapse-content-box {
                margin-top: 0.75rem;
                padding: 0;

                color: #000;
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 1rem; /* 133.333% */
              }
            }
          }

          .ant-collapse-item:last-child {
            border-radius: 1rem;
          }
        }
      }
    }
  }
}

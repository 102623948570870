@import "@/styles/varibles.less";

.second-page-container {
  background-color: #fff;
  padding: 0rem 0.75rem;
  padding-top: 0.75rem;

  .second-page-layout {
    width: 100%;
    .main-content {
      width: 100%;
      padding: 1.25rem;
      border-radius: 0rem 1.75rem 1.75rem 1.75rem;
      background: #f2f4f5;

      .main-title {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.25rem; /* 125% */
      }

      .main-job-block {
        border-left: 2px solid #000;
        padding-inline: 1rem;
        width: 9rem;
        flex-grow: 1;

        .main-job-block-count {
          color: #000;
          font-size: 1.25rem;
          font-weight: 700;
          line-height: 1.5rem; /* 120% */
        }
        .main-job-block-description {
          color: #000;
          font-size: 0.6875rem;
          font-weight: 500;
          line-height: 1rem; /* 145.455% */
        }
      }

      .job-cards-container {
        height: 16.5rem;
      }

      .carousel-dots {
        > li {
          width: auto !important;
          height: fit-content !important;
          border-radius: 50%;
          background: var(--black-10, rgba(0, 0, 0, 0.2)) !important;
        }
        > li > button {
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
        }

        :global {
          li.slick-active button {
            background-color: #000;
          }
        }
      }

      .main-job-card {
        height: 4.5rem;
        padding: 1rem;

        border-radius: 1rem;
        background: #fff;

        display: flex;
        align-items: center;
        gap: 16px;

        .main-job-card-company-info {
          color: #000;
          font-size: 11px;
          font-weight: 600;
          line-height: 16px; /* 145.455% */
          text-transform: capitalize;
        }

        .main-job-card-extra-info {
          color: #000;
          font-size: 11px;
          font-weight: 400;
          line-height: 16px; /* 145.455% */
          text-transform: capitalize;

          &::after {
            content: "hours ago";
          }
        }

        .main-job-card-title {
          color: var(--Black, #000);
          font-size: 13px;
          font-weight: 600;
          line-height: 20px; /* 153.846% */
          text-transform: capitalize;
        }
      }

      .text-container {
        display: none;
      }

      .second-page-bg {
        display: none;
      }
    }
  }
}

.company-logo {
  border-radius: 4px;
  background: #fff;
  position: relative;
  margin-left: 1px;
  margin-right: 1px;

  .company-logo-img {
    border-radius: 4px;
  }

  .company-logo-top-class {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.fourth-page-container {
  background-color: #fff;
  padding: 0 0.75rem;
  padding-top: 0.75rem;

  .fourth-page-layout {
    width: 100%;
    .main-content {
      width: 100%;
      padding: 1.25rem 1.25rem 3rem 1.25rem;
      border-radius: 0rem 1.75rem 1.75rem 1.75rem;
      background: #f2f4f5;
      .main-content-title {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2rem; /* 133.333% */
        margin-bottom: 1.5rem;
        text-transform: uppercase;
      }

      .main-content-block {
        padding-bottom: 3rem;
        border-bottom: 2px solid rgba(0, 0, 0, 0.03);

        &:not(:first-of-type) {
          padding-top: 3rem;
        }

        &:last-child {
          padding-bottom: 0;
          border-bottom: 0;
        }

        .sub-content-title {
          font-size: 1.25rem;
          font-weight: 700;
          line-height: 1.5rem; /* 120% */

          b {
            font-weight: 700;
          }

          span {
            font-weight: 400;
          }
        }

        .sub-content-list {
          padding: 0;
          margin: 0;

          font-size: 0.75rem;
          font-weight: 400;
          line-height: 1rem; /* 133.333% */
          list-style: none;

          > li::before {
            content: "•";
            display: inline-block;
            margin-right: 0.5rem;
          }

          li {
            margin-bottom: 0.5rem;
          }
        }

        .sub-content-img {
          margin: 0 auto;
        }

        .sub-content-info-left {
          display: flex;
          justify-content: space-between;
        }
      }

      .mobile-sign-up-button {
        border-radius: 1.75rem;
        background: #000;

        display: flex;
        // padding: 0.5rem 0rem;
        height: 2.5rem;
        justify-content: center;
        align-items: center;

        color: #fff;
        text-align: center;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.5rem; /* 150% */
        width: 12.5rem;
        margin: 0 auto;
      }
    }
  }
}

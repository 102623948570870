.headerTop {
  width: 100%;
  min-width: 100%;
  // height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 999;
  transition: top 0.3s ease-in-out;
}

.header.layout-header {
  width: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  display: flex;
  padding: 0 16px;

  &.mobile {
    height: fit-content;
    min-width: 100%;
  }

  .text {
    cursor: pointer;
    color: var(--Black, #000);
    font-size: 16px;

    font-weight: 600;
    line-height: 20px; /* 125% */
  }

  .menu-button {
    padding: 0;
    width: fit-content;
  }

  .profile {
    display: flex;
    align-items: center;

    transform: scale(0.7777);
    transform-origin: right;

    button.enterprise-invite-button {
      color: #000;

      display: inline-flex;
      padding: 4px 12px 4px 8px;
      justify-content: center;
      align-items: center;

      color: var(--Black, #000);
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
      text-transform: uppercase;

      border-radius: 16px;
      background: var(--Green, #00f0a0);

      border: none;

      &:hover {
        background-color: #00f0a0cc;
        color: var(--Black, #000);
      }

      :global {
        .ant-btn-icon {
          margin-inline-end: 4px;
        }
      }
    }

    button.mobile-sign-up-button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      background-color: #000;
      color: #fff;

      height: 28px;
      width: 96px;
    }

    button.mobile-sign-in-button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      color: var(--Black, #000);

      &:hover {
        span {
          color: #000;
        }
      }
    }

    :global {
      button.ant-btn {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        text-transform: uppercase;

        box-shadow: none;
        border: none;
      }
    }

    :global {
      .ant-space {
        &-item {
          color: #0a0a0a;
        }
      }
    }
  }

  .logo {
    transform: scale(0.7777);
    transform-origin: left;
  }
}

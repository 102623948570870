#mobile-side-menu-drawer-id.mobile-side-menu-drawer {
  padding: 16px;
  background-color: #fff;

  :global {
    .ant-drawer-header {
      padding: 44px 0 16px 0;
      border-bottom: 1px solid #f2f4f5;
    }

    .ant-menu-item-divider {
      background: var(--Grey, #f2f4f5);
    }

    .ant-drawer-footer {
      padding: 16px 0 0 0;
      display: flex;
      flex-flow: column nowrap;
      border-top-color: #f2f4f5;

      .ant-btn {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px; /* 114.286% */
        height: 48px;

        width: fit-content;
        display: flex;
        align-items: center;
        padding: 0;

        border: none;
        box-shadow: none;
        transition: none;

        background-color: transparent;

        outline: none;

        .ant-btn-icon {
          height: 20px;
          width: 20px;
        }
      }
    }

    .ant-drawer-close {
      transition: none;
      padding: 0;
      margin: 0;
      width: 20px;
      height: 20px;
    }

    .ant-drawer-body {
      margin-block-start: 16px;
    }
  }

  .mobile-side-menu-drawer-menu {
    border: none;
    background-color: #fff;

    :global {
      .ant-menu-item {
        padding-inline: 0;
        margin: 0;
        height: 48px;
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        transition: none;
        outline: none;
        outline-offset: 0;

        .ant-menu-title-content {
          margin-inline-start: 8px;

          .ant-typography {
            color: #000;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px; /* 114.286% */
          }
        }

        &.ant-menu-item-selected {
          background-color: transparent;
          color: #000;
        }
      }
    }
  }
}

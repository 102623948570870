@import "@/styles/varibles.less";

.third-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 0.75rem;
  background-color: #fff;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  .third-page-layout {
    width: 100%;

    .main-content {
      height: fit-content;
      padding: 1.25rem;
      width: 100%;
      border-radius: 1.75rem 0rem 1.75rem 1.75rem;
      background: rgba(0, 240, 160, 1);

      .main-content-title {
        width: 15rem;

        text-transform: uppercase;
        color: #000;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2rem; /* 133.333% */
      }

      .main-content-sub-container {
        align-items: flex-end;

        .main-content-subtitle {
          color: var(--Black, #000);
          text-align: right;
          font-size: 11px;
          font-weight: 400;
          line-height: 14px; /* 127.273% */
        }
      }

      .chat-container {
        width: 100%;
        height: 30.5rem;

        .chat-item {
          width: 100%;
          padding: 1rem;
          background-color: #fff;
          border-radius: 1rem 0rem 1rem 1rem;
          background: #fff;

          .chat-item-paragraph {
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1rem; /* 133.333% */
          }

          .chat-avatar {
            border-radius: 50%;
          }

          .chat-username {
            font-size: 0.6875rem;
            font-weight: 600;
            line-height: 1rem; /* 145.455% */
          }

          .chat-job-title {
            font-size: 0.6875rem;
            font-weight: 300;
            line-height: 1rem;
          }
        }
      }

      .chat-item-carousel {
        display: flex;
        flex-flow: column;
        gap: 8px;

        .carousel-dots {
          // bottom: -8px;
          position: relative;
          bottom: 0;

          > li {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.2);
          }

          > li > button {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: transparent;
          }

          :global {
            li.slick-active {
              background: #000;
            }
          }
        }
      }
    }
  }
}

.topBanner {
  height: var(--banner-height, 80px);
  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #b6eeda;
  color: #333;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  gap: 12px;
  transition: position 0.3s ease-in-out;
}

.bannerLeft {
  display: flex;
  align-items: center;
  gap: 12px;
}

.bannerIcon {
  border-radius: 14px;
  object-fit: cover;
}

.bannerInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.bannerTitle {
  font-weight: bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px; /* 125% */
}

.bannerSubtitle {
  color: var(--subFill, rgba(0, 0, 0, 0.4));
  font-size: 13px;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
}

.bannerStars {
  display: flex;
  gap: 4px;
}

.bannerRight {
  display: flex;
  align-items: center;
  gap: 12px;
}

.installBtn {
  border-radius: 16px;
  background: var(--mainFill, #000);
  display: flex;
  border: none;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
}

.closeBtn {
  background: transparent;
  border: none;
  padding: 0;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
